<template>
  <!-- Navbar -->
  <nav
    class="md:flex absolute top-0 left-0 w-full md:flex-row md:flex-nowrap md:justify-start items-center p-4 hidden"
    :class="$route.path == '/admin/dashboard' ? ' z-10' : ''"
  >
    <div
      class="w-full mx-auto items-center flex justify-end md:flex-nowrap flex-wrap md:px-2 px-4"
    >
      <!-- User -->
      <div class="flex items-center">
        <div class="mx-6 flex text-purple">
          <svg
            class="mr-2"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.4905 3.7835C18.3503 2.55244 16.7577 1.87451 14.9999 1.87451C13.2327 1.87451 11.6348 2.54834 10.4999 3.77178C9.35261 5.00869 8.79362 6.68974 8.92487 8.50498C9.18503 12.0862 11.9102 14.9995 14.9999 14.9995C18.0895 14.9995 20.81 12.0868 21.0743 8.50615C21.2073 6.70732 20.6448 5.02978 19.4905 3.7835Z"
              fill="#0C2568"
            />
            <path
              d="M25.3124 28.1232H4.68741C4.41744 28.1267 4.15009 28.07 3.90481 27.9572C3.65952 27.8444 3.44247 27.6783 3.26944 27.4711C2.88858 27.0158 2.73506 26.3941 2.84874 25.7654C3.34327 23.0221 4.88663 20.7176 7.31241 19.0998C9.46748 17.6637 12.1974 16.8732 14.9999 16.8732C17.8024 16.8732 20.5323 17.6642 22.6874 19.0998C25.1132 20.717 26.6565 23.0215 27.1511 25.7648C27.2647 26.3935 27.1112 27.0152 26.7304 27.4705C26.5574 27.6778 26.3404 27.844 26.0951 27.9569C25.8498 28.0699 25.5824 28.1267 25.3124 28.1232Z"
              fill="#0C2568"
            />
          </svg>
          {{
            authStore?.user?.user?.first_name
              ? authStore.user.user.first_name
              : "Imane akkal"
          }}
        </div>
        <div class="mx-6 flex text-purple cursor-pointer" @click="logout">
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.54541 21H5.54541C5.01498 21 4.50627 20.7893 4.1312 20.4142C3.75612 20.0391 3.54541 19.5304 3.54541 19V5C3.54541 4.46957 3.75612 3.96086 4.1312 3.58579C4.50627 3.21071 5.01498 3 5.54541 3H9.54541"
              stroke="#838E9E"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16.5454 17L21.5454 12L16.5454 7"
              stroke="#838E9E"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M21.5454 12H9.54541"
              stroke="#838E9E"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
  </nav>
  <!-- End Navbar -->
</template>

<script>
import { useAuthStore } from "@/store/auth";
export default {
  components: {},
  data() {
    return { isNewNotifications: false };
  },
  methods: {
    logout() {
      this.authStore.disconnect();
    },
  },
  setup() {
    const authStore = useAuthStore();
    return {
      authStore,
    };
  },
};
</script>
