import { defineStore } from "pinia";
import axios from "axios";
import router from "@/router";
export const useAuthStore = defineStore("authStore", {
  state: () => ({
    error: null,
    user: null,
    email: null,
    collapseShow: "hidden",
    errorMessage: "",
    isLoading: false,
    isFailEdit: false,
    isSuccessEdit: false,
    resetCode: "",
    successReset: false,
    countries: [],
  }),

  actions: {
    async authenticate(email, password) {
      console.log(`${process.env.VUE_APP_API_BASE}auth/teacher/login`);
      this.isLoading = true;

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_BASE}auth/teacher/login`,
          { email: email, password: password }
        );
        this.user = {
          user: response.data.data.user,
          token: response.data.data.token,
        };
        localStorage.setItem("rayhane-influencer", JSON.stringify(this.user));
        this.isLoading = false;
        this.isLoading = false;
        console.log(response);
        router.push("/admin/dashboard");
      } catch (err) {
        console.log(err);
        this.isLoading = false;
        this.error = "Invalid email or password";
      }
    },
    async sendResetCode(email) {
      this.error = null;
      this.isLoading = true;
      console.log(email);
      try {
        const data = await axios.post(
          `${process.env.VUE_APP_API_BASE}auth/teacher/forgot_password`,
          {
            email: email,
          }
        );
        console.log(data);
        this.isLoading = false;
        this.email = email;
        router.push("/auth/confirmcode");
      } catch (err) {
        console.log(err);
        this.isLoading = false;
        this.error =
          "The user account associated with this email is not active";
      }
    },
    async verifyResetCode() {
      this.error = null;
      this.isLoading = true;
      try {
        console.log();
        await axios.post(
          `${process.env.VUE_APP_API_BASE}auth/teacher/check_code`,
          {
            email: this.email,
            code: this.resetCode,
          }
        );
        this.isLoading = false;
        router.push("/auth/newPassword");
      } catch (err) {
        this.isLoading = false;
        this.error = "Incorrect code";
      }
    },
    async resetPassword(newPassword) {
      this.error = null;
      this.isLoading = true;
      try {
        await axios.post(
          `${process.env.VUE_APP_API_BASE}auth/teacher/reset_password`,
          {
            email: this.email,
            code: this.resetCode,
            password: newPassword,
            password_confirmation: newPassword,
          }
        );
        this.successReset = true;
        this.isLoading = false;
        this.error = false;
      } catch (err) {
        this.isLoading = false;
        this.error = "Incorrect code ";
      }
    },
    async updateProfile(newProfile) {
      this.error = null;
      this.isLoading = true;
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        const response = await axios.put(
          `${process.env.VUE_APP_API_BASE}admin/updateProfile`,
          newProfile,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoading = false;
        this.isSuccessEdit = true;
        this.user = response.data.user;
        let newAdminData = JSON.parse(admin);
        newAdminData.user = response.data.user;
        localStorage.setItem("draft-admin", JSON.stringify(newAdminData));
      } catch (err) {
        if (err.response.status == 401) {
          this.errorMessage = "Incorrect ancien password";
        }
        console.log(err);
        this.isLoading = false;
        this.isFailEdit = true;
      }
    },
    toggleCollapseShow(classes) {
      this.collapseShow = classes;
    },
    disconnect() {
      this.user = null;
      localStorage.removeItem("rayhane-influencer");
      router.push("/auth");
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
  },
});
