import { createRouter, createWebHistory } from "vue-router";
// layouts
import AdminLayout from "../layouts/AdminLayout.vue";
import LoginLayout from "../layouts/LoginLayout.vue";
// views

///1-login
import LoginView from "../views/login/LoginView.vue";
import ForgotPassword from "../views/login/ForgotPaswordView.vue";
import NewPassword from "../views/login/NewPasswordView.vue";
import ConfirmCode from "../views/login/ConfirmCodeView.vue";
///2-dashboard
import MainPage from "../views/dashboard/MainPage.vue";
import { useAuthStore } from "@/store/auth";

const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/auth",
  },
  {
    path: "/auth",
    name: "auth",
    redirect: "/auth/login",
    component: LoginLayout,
    children: [
      {
        path: "/auth/login",
        name: "login",
        component: LoginView,
      },
      {
        path: "/auth/forgotPassword",
        name: "ForgotPassword",
        component: ForgotPassword,
      },
      {
        path: "/auth/confirmCode",
        name: "confirmCode",
        component: ConfirmCode,
      },
      {
        path: "/auth/newPassword",
        name: "NewPassword",
        component: NewPassword,
      },
    ],
  },

  {
    path: "/admin",
    redirect: "/admn/dashbioard",
    meta: { requiresAuth: true },
    component: AdminLayout,
    children: [
      {
        path: "/admin/dashboard",
        component: MainPage,
        name: "admin-dashboard",
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  try {
    const rayhaneInfluencer = localStorage.getItem("rayhane-influencer");
    const authStore = useAuthStore();
    const requiresAuth = to.matched.some((route) => route.meta.requiresAuth);
    const isAuthenticated = authStore.user || rayhaneInfluencer ? true : false;

    authStore.toggleCollapseShow("hidden");

    if (rayhaneInfluencer) {
      authStore.user = JSON.parse(rayhaneInfluencer);
    }
    if (requiresAuth && !isAuthenticated) {
      // If route requires authentication and user is not authenticated, redirect to login
      return next({ name: "login" });
    }

    if (!requiresAuth && isAuthenticated) {
      // If route does not require authentication and user is authenticated, redirect to dashboard
      return next({ name: "admin-dashboard" });
    }

    // For other cases, proceed with the navigation
    next();
  } catch (error) {
    console.error(error);
    next();
  }
});
export default router;
