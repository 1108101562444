<template>
  <div
    class="flex justify-center items-center p-2 rounded-xl"
    :class="isSelected ? 'bg-[#00DDAF]' : 'bg-white'"
  >
    <svg
      width="21"
      height="21"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 18C1.45 18 0.979002 17.804 0.587002 17.412C0.195002 17.02 -0.000664969 16.5493 1.69779e-06 16V2C1.69779e-06 1.45 0.196002 0.979002 0.588002 0.587002C0.980002 0.195002 1.45067 -0.000664969 2 1.69779e-06H6C6.55 1.69779e-06 7.021 0.196002 7.413 0.588002C7.805 0.980002 8.00067 1.45067 8 2V16C8 16.55 7.804 17.021 7.412 17.413C7.02 17.805 6.54934 18.0007 6 18H2ZM12 18C11.45 18 10.979 17.804 10.587 17.412C10.195 17.02 9.99934 16.5493 10 16V11C10 10.45 10.196 9.979 10.588 9.587C10.98 9.195 11.4507 8.99934 12 9H16C16.55 9 17.021 9.196 17.413 9.588C17.805 9.98 18.0007 10.4507 18 11V16C18 16.55 17.804 17.021 17.412 17.413C17.02 17.805 16.5493 18.0007 16 18H12ZM12 7C11.45 7 10.979 6.804 10.587 6.412C10.195 6.02 9.99934 5.54934 10 5V2C10 1.45 10.196 0.979002 10.588 0.587002C10.98 0.195002 11.4507 -0.000664969 12 1.69779e-06H16C16.55 1.69779e-06 17.021 0.196002 17.413 0.588002C17.805 0.980002 18.0007 1.45067 18 2V5C18 5.55 17.804 6.021 17.412 6.413C17.02 6.805 16.5493 7.00067 16 7H12Z"
        :fill="color"
      />
    </svg>
  </div>
</template>
<script>
export default {
  props: ["color", "isSelected"],
};
</script>
<style></style>
