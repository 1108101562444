<template>
  <div ref="otpCont">
    <input
      type="text"
      class="md:m-2 m-1 border-2 h-14 w-14 text-center form-control rounded focus:outline-primary"
      :class="{
        bounce: digits[ind] !== null,
        'outline-red-500 ': validationError,
      }"
      v-for="(el, ind) in digits"
      :key="el + ind"
      v-model="digits[ind]"
      :autofocus="ind === 0"
      maxlength="1"
      @keydown="handleKeyDown($event, ind)"
    />
  </div>
</template>

<script>
import { useAuthStore } from "@/store/auth";
export default {
  props: {
    digitCount: {
      type: Number,
      required: true,
    },
    validationError: {
      type: Boolean,
      default: false,
    },
    default: {
      type: String,
      default: "",
    },
  },
  setup() {
    const authStore = useAuthStore();
    return { authStore };
  },
  data() {
    return {
      digits: [],
      otpCont: null,
    };
  },
  mounted() {
    this.initializeDigits();
  },
  methods: {
    initializeDigits() {
      if (this.default && this.default.length === this.digitCount) {
        for (let i = 0; i < this.digitCount; i++) {
          this.digits[i] = this.default.charAt(i);
        }
      } else {
        for (let i = 0; i < this.digitCount; i++) {
          this.digits[i] = null;
        }
      }
    },
    isDigitsFull() {
      for (const elem of this.digits) {
        if (elem == null || elem == undefined) {
          return false;
        }
      }
      this.authStore.resetCode = this.digits.join("");
      return true;
    },
    handleKeyDown(event, index) {
      if (
        event.key !== "Tab" &&
        event.key !== "ArrowRight" &&
        event.key !== "ArrowLeft"
      ) {
        event.preventDefault();
      }

      if (event.key === "Backspace") {
        this.digits[index] = null;

        if (index != 0) {
          this.$refs.otpCont.children[index - 1].focus();
        }
        return;
      }
      if (new RegExp("^([0-9])$").test(event.key)) {
        this.digits[index] = event.key;
        if (index != this.digitCount - 1) {
          this.$refs.otpCont.children[index + 1].focus();
        }
        if (this.isDigitsFull()) {
          this.$emit("update:otp", this.digits.join(""));
        }
      }
    },
  },
};
</script>

<style scoped>
.digit-box {
  height: 4rem;
  width: 2rem;
  border: 2px solid black;
  display: inline-block;
  border-radius: 5px;
  margin: 5px;
  padding: 15px;
  font-size: 3rem;
}
.digit-box:focus {
  outline: 3px solid black;
}
.bounce {
  animation: pulse 0.3s ease-in-out alternate;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
</style>
