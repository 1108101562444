import { defineStore } from "pinia";
import axios from "axios";
import router from "@/router";
export const useIncomesStore = defineStore("incomesStore", {
  state: () => ({
    error: null,
    user: null,
    email: null,
    isLoading: false,
    isFailEdit: false,
    isSuccessEdit: false,
    successReset: false,
    incomes: [],
  }),

  actions: {
    async getIncomes() {
      this.error = null;
      this.isLoading = true;
      try {
        const admin = localStorage.getItem("rayhane-influencer");
        const token = JSON.parse(admin).token;
        await axios.get(
          `${process.env.VUE_APP_API_BASE}influencer/transaction/get`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        this.error = "Invalid email or password";
      }
    },

    disconnect() {
      this.user = null;
      localStorage.removeItem("rayhane-influencer");
      router.push("/auth");
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
  },
});
