<template>
  <div class="flex flex-wrap mt-4 self-stretch items-stretch">
    <div class="my-6 mt-24 w-full">
      <IncomesTable />
    </div>
  </div>
</template>
<script>
import IncomesTable from "@/components/tables/IncomesTable.vue";

export default { components: { IncomesTable } };
</script>
<style></style>
