<template>
  <div>
    <p class="text-center opacity-40" v-if="!this.authStore.successReset">
      Change your password.
    </p>
    <form
      @click.prevent="changePassword"
      class="flex flex-col items-center my-8"
    >
      <div v-if="!this.authStore.successReset">
        <label class="opacity-40" for="password">New password</label><br />
        <input
          class="border rounded-md mb-4 mt-2 text-gray-700 py-3 px-2 leading-tight focus:outline-primary mx-auto md:min-w-[400px] min-h-[200px]"
          type="password"
          v-model="password"
          name="password"
        />
        <div v-if="v$.password.$error" class="text-red-500">
          Password must be at least 8 characters long
        </div>
      </div>
      <div v-if="!this.authStore.successReset">
        <label class="opacity-40" for="passwordRepeat">Re-write password</label
        ><br />
        <input
          class="border rounded-md mb-4 mt-2 text-gray-700 py-3 px-2 leading-tight focus:outline-primary mx-auto lg:min-w-[400px] md:min-w-[400px] min-h-[200px]"
          type="password"
          v-model="passwordRepeat"
          name="passwordRepeat"
        />
        <div v-if="v$.passwordRepeat.$error" class="text-red-500">
          Passwords do not match
        </div>
      </div>
      <p
        v-if="this.authStore.successReset"
        class="text-center font-semibold text-[#092674] text-md"
      >
        <svg
          width="130"
          class="mx-auto"
          height="186"
          viewBox="0 0 187 186"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.81543"
            y="0.183716"
            width="185.368"
            height="185.368"
            rx="92.6842"
            fill="#1FE32C"
            fill-opacity="0.16"
          />
          <path
            d="M74.1907 92.8679L87.0635 105.741L112.809 79.9951M151.428 92.8679C151.428 124.86 125.492 150.796 93.4999 150.796C61.5073 150.796 35.5723 124.86 35.5723 92.8679C35.5723 60.8754 61.5073 34.9403 93.4999 34.9403C125.492 34.9403 151.428 60.8754 151.428 92.8679Z"
            stroke="#1FE32C"
            stroke-width="3.08947"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        Password reseted successfully !
        <button
          @click="$router.push({ name: 'login' })"
          type="submit"
          class="text-white bg-[#092674] md:min-w-[400px] border-[#092674] border-4 rounded-lg px-2 py-4 text-xl font-semibold mt-10 block mx-auto min-w-[200px]"
        >
          back to login
        </button>
      </p>
      <button
        v-if="!this.authStore.successReset"
        type="submit"
        class="my-6 text-white bg-[#092674] lg:min-w-[400px] rounded-lg px-2 py-4 text-xl font-semibold"
      >
        <span v-if="!this.authStore.successReset && !authStore.isLoading"
          >Change password</span
        >
        <div
          v-if="authStore.isLoading"
          class="flex justify-center"
          role="status"
        >
          <svg
            aria-hidden="true"
            class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-[#05B8AA]"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="white"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="cyan"
            />
          </svg>
          <span class="sr-only">Loading...</span>
        </div>
      </button>
    </form>
  </div>
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import { sameAs, minLength, required } from "@vuelidate/validators";
import { useAuthStore } from "@/store/auth";
import router from "@/router";
export default {
  data() {
    return {
      password: "",
      passwordRepeat: "",
    };
  },
  setup() {
    const authStore = useAuthStore();
    return { v$: useVuelidate(), authStore };
  },
  mounted() {
    this.authStore.error = null;
    if (!this.authStore.email || !this.authStore.resetCode) {
      router.push("/auth/login");
    }
  },
  methods: {
    async changePassword() {
      const result = await this.v$.$validate();
      if (!result) {
        return;
      }
      this.authStore.resetPassword(this.password);
    },
    redirectLogin() {
      this.authStore.successReset = false;
      router.push("/auth");
    },
  },
  validations() {
    return {
      passwordRepeat: { sameAsPassword: sameAs(this.password), required },
      password: { minLength: minLength(8), required },
    };
  },
};
</script>
