<template>
  <div class="max-w-[1728px] lg:mx-auto">
    <side-bar />
    <admin-navbar />
    <div class="relative xl:ml-72 2xl:ml-68 md:ml-80 bg-blueGray-100">
      <div
        class="px-4 md:px-5 mx-auto w-full"
        :class="
          $route.path == '/admin/dashboard' || $route.path == '/admin/news'
            ? '-m-24'
            : ''
        "
      >
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import AdminNavbar from "@/components/navbars/AdminNavbar.vue";
import SideBar from "@/components/sidebars/SideBar.vue";
export default {
  name: "admin-layout",
  components: {
    SideBar,
    AdminNavbar,
  },
};
</script>
