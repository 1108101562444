<template>
  <nav
    class="md:left-0 shadow-2xl md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden flex flex-wrap items-center justify-between relative md:w-72 z-10 py-4 px-4 bg-[#243752]"
  >
    <div
      class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
    >
      <!-- Toggler -->
      <button
        class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
        type="button"
        @click="authStore.toggleCollapseShow('bg-red m-2 py-3 px-6')"
      >
        <img :src="menuBurger" alt="menuBurger" />
      </button>
      <!-- Brand -->
      <router-link
        class="hidden md:flex justify-center items-center text-left md:pb-2 text-blueGray-600 mr-0 whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
        to="/"
      >
        <img :src="logo" class="inline" alt="logo" />
      </router-link>
      <!-- Collapse -->
      <div
        class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded"
        :class="authStore.collapseShow"
      >
        <div
          class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200 mt-18 bg-[#243752]"
        >
          <div class="flex flex-wrap">
            <div class="w-6/12">
              <router-link
                class="md:block text-left md:pb-2 text-white mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                to="/"
              >
                Influencer dashboard
              </router-link>
            </div>
            <div class="w-6/12 flex justify-end">
              <button
                type="button"
                class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                v-on:click="authStore.toggleCollapseShow('hidden')"
              >
                <img :src="closeMenu" alt="closeMenu" />
              </button>
            </div>
          </div>
        </div>
        <!-- Navigation -->
        <ul
          class="md:flex-col md:min-w-full mt-4 flex flex-col list-none text-[#999999]"
        >
          <li>
            <router-link
              to="/admin/dashboard"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="px-2 py-2 my-1 font-bold flex items-center rounded-xl bg-[#00DDAF]"
                :class="[
                  isActive || $route.path.includes('dashboard')
                    ? ' text-white font-semibold rounded-xl shadow-xl'
                    : ' hover:text-blueGray-500',
                ]"
              >
                <dashboard-icon
                  class="mx-2"
                  :isSelected="isActive"
                  :color="isActive ? 'white' : '#00DDAF'"
                />
                Dashboard
              </a>
            </router-link>
          </li>

          <li
            class="md:hidden block text-center text-xl mt-4 font-semibold"
            @click="authStore.disconnect"
          >
            <router-link to="/admin/dashboard">
              <a> Logout </a>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import DashboardIcon from "../../assets/icons/sidebar/DashboardIcon.vue";
import logo from "@/assets/icons/sidebar/logo.svg";
import menuBurger from "@/assets/icons/sidebar/menuBurger.svg";
import closeMenu from "@/assets/icons/sidebar/closeMenu.svg";
import { useAuthStore } from "@/store/auth";

export default {
  components: {
    DashboardIcon,
  },
  data() {
    return {
      collapseShow: "hidden",
    };
  },
  setup() {
    const authStore = useAuthStore();
    return {
      logo,
      menuBurger,
      authStore,
      closeMenu,
    };
  },
  methods: {
    toggleCollapseShow(classes) {
      this.collapseShow = classes;
    },
  },
};
</script>

<style scoped>
.svg-active {
  fill: white !important;
}
.svg-inactive {
  fill: #00ddaf !important;
}
.box-example2 {
  box-shadow: inset 0px 7px 9px -7px rgb(0 0 0 / 40%);
  height: 80px;
  border: 1px dashed grey;
  position: relative;
  margin-top: 8px;
}
nav::after {
  box-shadow: inset -7px 0px 090px -7px rgb(0 0 0 / 40%) !important;
}
</style>
