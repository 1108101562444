<template>
  <div class="login-page">
    <div
      class="flex 3xl:justify-between justify-center items-center pt-20 md:pt-0 max-w-[1728px] lg:mx-auto min-h-screen"
    >
      <div
        class="hidden md:flex justify-center xl:items-end self-center 2xl:items-center bg-primary w-[50vw] 2x:w-[35vw] 3xl:w-[40%]"
      >
        <img
          v-if="$route.name == 'login'"
          :src="loginImage"
          class="2xl:h-[1000px]"
          alt="ilustration"
        />
        <img
          v-if="$route.name !== 'login'"
          :src="loginImage2"
          class="2xl:h-[1000px]"
          alt="ilustration"
        />
      </div>
      <div class="lg:flex items-center 3xl:w-[50%] xl:max-w-[40%]">
        <div class="px-4 md:w-[50vw] 2xl:w-[55vw]">
          <img
            :src="logo"
            alt="logo"
            class="mx-auto w-28 mb-4 sm:mb-0 lg:w-[110px]"
          />
          <h1
            class="my-3 lg:my-6 text-[22px] lg:text-[33px] font-medium text-black text-center"
          ></h1>
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import logo from "@/assets/images/logo.png";
import loginImage from "@/assets/images/loginImage.png";
import loginImage2 from "@/assets/images/teacher2.png";
import { useAuthStore } from "@/store/auth";

export default {
  setup() {
    const authStore = useAuthStore();
    return { logo, loginImage, authStore, loginImage2 };
  },
};
</script>
<style scoped>
.login-page {
  background-color: #ddfff8;
}
input::-webkit-input-placeholder {
  color: rgba(41, 44, 48, 0.8);
  font-weight: 400;
}
.socials-logo {
  height: 20px;
}
</style>
