<template>
  <div>
    <!-- <BlockTeacherModal v-if="incomesStore.isBlockModal" />
    <UnBlockTeacherModal v-if="incomesStore.isUnblockModal" />
    <DeleteTeacherModal v-if="incomesStore.showDeleteModal" />
    <LoaderModal v-if="incomesStore.isLoadingModal" /> -->
    <div class="flex justify-evenly mt-28 mb-24">
      <div
        class="flex justify-between items-center shadow-lg rounded-md p-3 px-18"
      >
        <div
          class="bg-[#713BDB0D] rounded-full flex items-center justify-center w-16 h-16 mx-5"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 21C3 17.625 7.5 17.625 9.75 15.375C10.875 14.25 7.5 14.25 7.5 8.625C7.5 4.87538 8.99962 3 12 3C15.0004 3 16.5 4.87538 16.5 8.625C16.5 14.25 13.125 14.25 14.25 15.375C16.5 17.625 21 17.625 21 21"
              stroke="#6F52ED"
              stroke-width="2"
              stroke-linecap="square"
            />
          </svg>
        </div>
        <div class="mx-10">
          <div class="font-semibold text-gray-600 text-2xl">62</div>
          <div class="text-xl font-semibold text-gray-400">students</div>
        </div>
      </div>
      <div
        class="flex justify-between items-center shadow-lg rounded-md p-3 px-18"
      >
        <div
          class="bg-[#FFB80012] rounded-full flex items-center justify-center w-16 h-16 mx-5"
        >
          <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.66663 12.3492C8.66663 13.1834 9.31663 13.8659 10.1075 13.8659H11.7325C12.4258 13.8659 12.9891 13.27 12.9891 12.5442C12.9891 11.7534 12.6425 11.4717 12.1333 11.2875L9.53329 10.3775C9.01329 10.1934 8.66663 9.91169 8.66663 9.12086C8.66663 8.39503 9.22996 7.79919 9.92329 7.79919H11.5483C12.35 7.81003 13 8.48169 13 9.31586M10.8333 13.92V14.7217M10.8333 6.94336V7.78836"
              stroke="#FFB800"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14.0616 21.536C15.0366 22.9118 16.6291 23.811 18.4491 23.811C21.4066 23.811 23.8116 21.406 23.8116 18.4485C23.8116 16.6502 22.9233 15.0577 21.5691 14.0827M10.8225 19.4777C13.1181 19.4777 15.3198 18.5657 16.9431 16.9424C18.5663 15.3192 19.4783 13.1175 19.4783 10.8218C19.4783 8.52618 18.5663 6.32453 16.9431 4.70125C15.3198 3.07797 13.1181 2.16602 10.8225 2.16602C8.52679 2.16602 6.32514 3.07797 4.70186 4.70125C3.07858 6.32453 2.16663 8.52618 2.16663 10.8218C2.16663 13.1175 3.07858 15.3192 4.70186 16.9424C6.32514 18.5657 8.52679 19.4777 10.8225 19.4777Z"
              stroke="#FFB800"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="mx-10">
          <div class="font-semibold text-gray-600 text-2xl">
            {{ authSotre?.user?.user?.userable?.balance }} DA
          </div>
          <div class="text-xl font-semibold text-gray-400">balance</div>
        </div>
      </div>
    </div>
    <div
      class="relative min-w-0 break-words w-full mb-6 shadow-lg mt-4 rounded-xl border border-[#e8e8e8c9] bg-white"
    >
      <div class="block w-full overflow-x-auto">
        <table
          class="items-center w-full bg-transparent border border-[#e8e8e8c9] rounded-xl overflow-hidden"
          v-if="incomesStore.incomes.length > 0"
        >
          <thead class="">
            <tr class="border-[#e8e8e8c9] border">
              <th
                class="text-purple px-6 align-middle py-6 uppercase -0 font-medium text-left"
              >
                Teacher name
              </th>
              <th
                class="text-purple px-6 align-middle py-6 uppercase font-medium text-left"
              >
                Fonction
              </th>
              <th
                class="text-purple px-6 align-middle py-6 uppercase font-medium text-left"
              >
                Balance
              </th>
              <th
                class="text-purple px-6 align-middle py-6 uppercase font-medium text-center"
              >
                Percentage
              </th>
              <th
                class="text-purple px-6 align-middle py-6 uppercase font-medium text-left"
              >
                Courses
              </th>

              <th
                class="text-purple px-6 align-middle py-6 uppercase font-medium text-center"
              >
                Comments
              </th>
              <th
                class="text-purple px-6 align-middle py-6 uppercase font-medium text-center"
              >
                total rate
              </th>
              <th
                class="text-purple px-6 align-middle py-6 uppercase font-medium text-center"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(teacher, index) in incomesStore.teachers"
              :key="index"
              :class="teacher.user.status == 'inactive' ? 'bg-red-100' : ''"
            >
              <td class="align-middle p-4 text-purple flex items-center">
                <img
                  v-if="!teacher.user.image?.url"
                  :src="avatarUser"
                  class="w-16 h-16 mr-2"
                  alt="dfsd"
                />
                <img
                  class="w-16 h-16 mr-2"
                  v-else
                  :src="teacher.user.image.url"
                  alt="profile pic"
                />
                {{ teacher.user.first_name + " " + teacher.user.last_name }}
              </td>
              <td class="align-middle p-4 text-purple items-center">
                {{ teacher.function }}
              </td>
              <td class="align-middle p-4 text-center">
                {{ teacher.balance }}
              </td>

              <td class="align-middle whitespace-nowrap p-4 text-center">
                {{ teacher.percentage }} %
              </td>

              <td class="align-middle text-center">
                {{ teacher.courses_count }}
              </td>
              <td class="align-middle text-center">
                {{ teacher.comments_count }}
              </td>
              <td class="align-middle text-center">{{ teacher.total_rate }}</td>
              <td class="align-middle text-center"></td>
            </tr>
          </tbody>
        </table>
        <div v-else class="flex flex-col justify-center items-center py-10">
          <img :src="noRecords" alt="noRecords" class="w-2/6" />
          <div class="text-xl text-[#747373]">No records at the moment !</div>
        </div>
      </div>
    </div>
    <div class="flex justify-between items-center">
      <div>
        Showing {{ incomesStore.from }}-{{ incomesStore.to }} from
        {{ incomesStore.totalPages }} data
      </div>
      <div class="flex items-center">
        <div v-if="incomesStore.currentPage != 1">
          <img
            @click="incomesStore.previousPage()"
            :src="forward"
            class="cursor-pointer"
            alt="forward"
          />
        </div>

        <div
          class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12"
          :class="
            true
              ? 'bg-cyan text-white border-cyan'
              : ' text-gray border border-gray'
          "
        >
          {{ incomesStore.currentPage }}
        </div>

        <div
          v-if="
            incomesStore.currentPage + 1 <=
            Math.ceil(incomesStore.totalPages / incomesStore.itemsPerPage)
          "
        >
          <img
            class="cursor-pointer"
            @click="incomesStore.nextPage()"
            :src="back"
            alt="back"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import checkbox from "@/assets/icons/cards/checkbox.svg";
import separator from "@/assets/icons/cards/separator.svg";
import deleteIcon from "@/assets/icons/cards/deleteIcon.svg";
import avatarUser from "@/assets/icons/cards/avatarUser.svg";
import back from "@/assets/icons/cards/back.svg";
import forward from "@/assets/icons/cards/forward.svg";
import noRecords from "@/assets/images/noRecords.svg";
import { useIncomesStore } from "@/store/incomes";
import { useAuthStore } from "@/store/auth";
// import TeachersDropdown from "../dropdowns/TeachersDropdown.vue";
// import BlockTeacherModal from "../modals/teachers/BlockTeacherModal.vue";
// import UnBlockTeacherModal from "../modals/teachers/UnBlockTeacherModal.vue";
// import LoaderModal from "../modals/LoaderModal.vue";
// import DeleteTeacherModal from "../modals/teachers/DeleteTeacherModal.vue";
export default {
  components: {
    // TeachersDropdown,
    // BlockTeacherModal,
    // UnBlockTeacherModal,
    // LoaderModal,
    // DeleteTeacherModal,
  },
  data() {
    return {};
  },
  setup() {
    const incomesStore = useIncomesStore();
    const authSotre = useAuthStore();
    return {
      back,
      forward,
      checkbox,
      avatarUser,
      deleteIcon,
      separator,
      incomesStore,
      noRecords,
      authSotre,
    };
  },
  async mounted() {
    await this.incomesStore.getIncomes();
  },

  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
